import React from "react"
import data from "Src/data/imprint.json"
import Layout from "../components/Containers/Layout/Layout"
import SEO from "../components/seo" 
import BasicText from "../components/Sections/BasicText/BasicText"

const ImprintPage = () => {
  const about = data[0].textSection

  return (
    <Layout>
      <SEO title="Imprint" />
      <BasicText content={about} />
    </Layout>
  )
}

export default ImprintPage
